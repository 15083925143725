import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'

function JobPosition({ data }) {
  return (
    <Columns className="is-flex-mobile is-vcentered">
      <Columns.Column className="is-1 is-offset-3 has-centered-text">
        <span className="has-text-success icon is-large">
          <i className={`${data.datoCmsCareer.fontawesomeIconName} fa-2x`} />
        </span>
      </Columns.Column>
      <Columns.Column className="is-6 is-size-6 is-vcentered">
        <b>{data.datoCmsCareer.title}</b>
        <br />
        <b>Remote position</b>, HQ in{' '}
        <span
          itemProp="jobLocation"
          itemScope
          itemType="https://schema.org/Place"
        >
          <span
            itemProp="address"
            itemScope
            itemType="https://schema.org/PostalAddress"
          >
            <span itemProp="addressLocality">Leeds</span>,{' '}
            <span itemProp="addressRegion">West Yorkshire</span>
          </span>
        </span>
        <span className="is-hidden" itemProp="industry">
          Computer Software
        </span>
        <span
          className="is-hidden"
          itemProp="hiringOrganization"
          itemScope
          itemType="https://schema.org/Organization"
        >
          <span itemProp="name">Pocketworks Mobile Ltd.</span>
        </span>
        <br />
        <span itemProp="employmentType">Full-time</span>
      </Columns.Column>
    </Columns>
  )
}
JobPosition.propTypes = { data: PropTypes.object }

export default JobPosition
